import { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import {
  readPublicList,
  fetchListFromName,
  fetchUserFromName,
} from '../ghettoAPI'
import Microlink from '@microlink/react'

import { Modal, Button } from 'react-bootstrap'

import { useNavigate } from 'react-router-dom'

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

const LiveView = (props) => {
  let params = useParams()
  let userName = params.userName
  let rhName = params.rhName
  let UID = params.uid
  let listId = params.listId

  let navigate = useNavigate()

  console.log(params, 'params')

  const [featured, setFeatured] = useState([])
  const [title, setTitle] = useState('')
  const [description, setDescription] = useState('')
  const [displayName, setDisplayName] = useState(null)
  const [show, setShow] = useState(false)
  const [mode, setMode] = useState('table')
  const [showMedia, setShowMedia] = useState(true)

  const fetchData = async () => {
    let fetched = await readPublicList(UID, listId)
    if (fetched) {
      console.log(fetched)
      if (fetched.name) setTitle(fetched.name)
      if (fetched.description) setDescription(fetched.description)
      if (fetched.links) setFeatured(fetched.links)
    }
  }

  const fetchDataFromNames = async () => {
    let uid = await fetchUserFromName(userName)
    console.log(uid)

    let fbName = rhName
      .replaceAll('-', ' ')
      .replace(/(^\w{1})|(\s+\w{1})/g, (letter) => letter.toUpperCase())

    console.log(fbName)

    let rh = await fetchListFromName(uid, fbName)
    console.log(rh)
    if (uid && rh) {
      console.log(uid, rh)
      if (rh.data.name) setTitle(rh.data.name)
      if (rh.data.description) setDescription(rh.data.description)
      if (rh.data.links) setFeatured(rh.data.links)
    }
  }

  const handleClose = () => {
    setShow(false)
  }

  const handleFollow = () => {
    alert('following!')
  }

  useEffect(() => {
    // if (loading) {
    //   // maybe trigger a loading screen
    //   return
    // }
    if (userName) {
      // fetch userId from userName
      // fetch listId from rhName
      console.log('fetching data from names')
      fetchDataFromNames()
    } else {
      fetchData()
    }
    // console.log(lists)
    // console.log('lists from fb', lists)
    // if (!user) navigate('/')
  }, [])

  // const renderSections = (links) => {
  //    return links.map((item)=>{
  //         return {
  //             url: item.URL,
  //             caption: item.caption,
  //           }
  //     })
  // }

  let renderTags = (_tags) => {
    return _tags.map((tag, index) => {
      return (
        <span
          key={index}
          className="inline-flex items-center px-2.5 py-0.5 rounded-md text-sm font-medium bg-purple-100 text-purple-800"
        >
          {tag.label}
        </span>
      )
    })
  }

  let renderCascade = () => {
    return (
      <div className="mt-16 space-y-16">
        {featured.map((feature, featureIdx) => (
          <div
            key={feature.URL}
            className="flex flex-col-reverse lg:grid lg:grid-cols-12 lg:gap-x-8 lg:items-center"
          >
            <div
              className={classNames(
                featureIdx % 2 === 0
                  ? 'lg:col-start-1'
                  : 'lg:col-start-8 xl:col-start-9',
                'mt-6 lg:mt-0 lg:row-start-1 lg:col-span-5 xl:col-span-4',
              )}
            >
              {/* <h3 className="text-lg font-medium text-gray-900">{feature.name}</h3> */}
              <p className="mt-2 text-sm text-gray-500">{feature.caption}</p>
              {renderTags(feature.tags)}
            </div>
            <div
              className={classNames(
                featureIdx % 2 === 0
                  ? 'lg:col-start-6 xl:col-start-5'
                  : 'lg:col-start-1',
                'flex-auto lg:row-start-1 lg:col-span-7 xl:col-span-8',
              )}
            >
              <div className="rounded-lg overflow-hidden">
                {showMedia ? (
                  <Microlink url={feature.URL} />
                ) : (
                  <a
                    href={feature.URL}
                    target={'_blank'}
                    rel="noopener noreferrer"
                  >
                    {feature.URL}
                  </a>
                )}
              </div>
            </div>
          </div>
        ))}
      </div>
    )
  }

  let renderTable = () => {
    const TH = () => {
      // boilerplate table header functional component
      return (
        <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
          <tr>
            {/* <th scope="col" className="px-6 py-3">
              Verified
            </th> */}
            <th scope="col" className="px-6 py-3">
              Link
            </th>
            {/* <th scope="col" className="px-6 py-3">
              Last Modified
            </th> */}
          </tr>
        </thead>
      )
    }

    let renderTags = (_tags) => {
      return _tags.map((tag, index) => {
        return (
          <span
            key={index}
            className="inline-flex items-center px-2.5 py-0.5 rounded-md text-sm font-medium bg-purple-100 text-purple-800"
          >
            {tag.label}
          </span>
        )
      })
    }

    const rows = featured.map((row, index) => {
      return (
        <tr
          key={index}
          class="bg-white border-b dark:bg-gray-800 dark:border-gray-700"
        >
          <td className="px-12 py-4 flex">
            <div className="w-full flex flex-col">
              {showMedia ? (
                <Microlink url={row.URL} />
              ) : (
                <a href={row.URL} target={'_blank'} rel={'noopener noreferrer'}>
                  {row.URL}
                </a>
              )}

              <div className="mt-6">{row.caption}</div>

              <div className="flex-row mt-6">{renderTags(row.tags)}</div>
            </div>
          </td>
        </tr>
      )
    })

    return (
      <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
        <TH />
        <tbody>{rows}</tbody>
      </table>
    )
  }

  let changeModeButton = (
    <button
      className="px-6 py-2.5 bg-gray-200 text-gray-700 font-medium text-xs leading-tight uppercase rounded shadow-md hover:bg-purple-700 hover:text-white hover:shadow-lg transition duration-150 ease-in-out display:block"
      onClick={(e) => {
        e.preventDefault()
        setMode(mode === 'table' ? 'cascade' : 'table')
      }}
    >
      {mode === 'table' ? 'Show Cascading' : 'Show as Table'}
    </button>
  )

  let toggleMediaButton = (
    <button
      className="px-6 py-2.5 bg-gray-200 text-gray-700 font-medium text-xs leading-tight uppercase rounded shadow-md hover:bg-purple-700 hover:text-white hover:shadow-lg transition duration-150 ease-in-out display:block"
      onClick={(e) => {
        e.preventDefault()
        setShowMedia(!showMedia)
      }}
    >
      {showMedia ? 'Hide Link Previews' : 'Show Link Previews'}
    </button>
  )

  return (
    <div className="bg-white">
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>
            <p>
              Follow this user on{' '}
              <a href="https://rahguyon1.wixsite.com/vibrate">Vibe/Rate</a> to
              stay in the loop with their public updates{' '}
            </p>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {/* <button
            className="px-6 py-2.5 bg-gray-200 text-gray-700 font-medium text-xs leading-tight uppercase rounded shadow-md hover:bg-purple-700 hover:text-white hover:shadow-lg transition duration-150 ease-in-out display:block"
            onClick={handleFollow}
          >
            Follow +
          </button> */}
          <button
            className="ml-2 px-6 py-2.5 bg-gray-200 text-gray-700 font-medium text-xs leading-tight uppercase rounded shadow-md hover:bg-purple-700 hover:text-white hover:shadow-lg transition duration-150 ease-in-out display:block"
            onClick={(e) => {
              e.preventDefault()
              window.open('https://rahguyon1.wixsite.com/vibrate')
            }}
          >
            Learn More!
          </button>
        </Modal.Body>
      </Modal>
      <div className="max-w-2xl mx-auto py-24 px-4 sm:px-6 sm:py-32 lg:max-w-7xl lg:px-8">
        <div className="max-w-3xl mx-auto text-center">
          <h2 className="text-3xl font-extrabold tracking-tight text-gray-900 sm:text-4xl">
            {title}
          </h2>
          <br />
          {userName && (
            <div
              className="text-3xl font-extrabold tracking-tight text-gray-900 sm:text-4xl"
              onClick={() => {
                setShow(true)
              }}
              onMouseOverCapture={() => {
                console.log('mouse over capture')
                setShow(true)
              }}
              onMouseOver={() => {
                console.log('mouse over')
                setShow(true)
              }}
            >
              Created by:{' '}
              <span
                style={{
                  cursor: 'pointer',
                  color: 'blue',
                  textDecoration: 'underline',
                }}
              >{`@${userName}`}</span>
            </div>
          )}
          <p className="mt-4 text-gray-500">{description}</p>
        </div>

        <div className="mt-16 space-y-16">
          <div className="flex-row">
            {changeModeButton} {toggleMediaButton}
          </div>
          {mode == 'table' ? renderTable() : renderCascade()}
        </div>
      </div>
    </div>
  )
}

export default LiveView
