import { useState, useEffect } from 'react'

import Table from './Table'
import Form from './Form'
import { ValidationModal } from './AppModals'

import { db, auth } from '../Firebase'
import { collection, addDoc } from 'firebase/firestore'
import { useAuthState } from 'react-firebase-hooks/auth'

import {
  updateList,
  fetchProfile,
  changePublishStatus,
  updateFeed,
} from '../ghettoAPI'

import { useSelector } from 'react-redux'

const LinkContainer = (props) => {
  const [links, setLinks] = useState(null)
  const [user, loading] = useAuthState(auth)
  const [userInfo, setUserInfo] = useState(null)

  const [index, setIndex] = useState(props.index)
  const [showVF, setShowVF] = useState(false)
  // const [loading, setLoading] = useState(false)

  console.log(props.index, 'index')

  const userReducer = useSelector((state) => state.user)
  const UID = userReducer.uid

  const rabbitHoles = useSelector((state) => state.rabbitHoles)
  const rabbitHole = rabbitHoles.lists.find((elem) => elem.id === props.data.id)
  console.log(links)
  console.log(rabbitHole.data.links)
  console.log(rabbitHole)
  useEffect(() => {
    if (rabbitHole && !links) {
      setLinks(rabbitHole.data.links)
      props.setPublishStatus(rabbitHole.data.published)
    }

    let fetchData = async (user_uid) => {
      let userInfo = await fetchProfile(user.uid)
      if (userInfo) {
        setUserInfo(userInfo)
      }
    }

    if (user && !loading) {
      console.log(user.uid)
      fetchData(user.uid)
    }
  })

  let handleRemove = (index) => {
    /* TODO - Create logic for setting the state to filter array and remove favLink at index */
    var temp = [...links]
    temp.splice(index, 1)
    // delete on Firebase as well
    setLinks(temp)
  }

  let sendToFirestore = (favLink) => {
    if (user) {
      try {
        addDoc(collection(db, 'users', `${user.uid}/rabbitHole`), {
          name: favLink.name,
          URL: favLink.URL,
        }).then((docRef) => {})
      } catch (e) {
        console.error('Error adding document: ', e)
      }
    } else {
      alert('You need to log in first!')
    }
  }

  let testSubmit = (favLink) => {
    // let newLinks = [...oldFavLinks, favLink]
    // console.log('should update the table', newLinks)
    setLinks([...links, favLink])
  }

  let handleSubmit = async (favLink) => {
    /* TODO - Create logic to setState and add new favLink to favLinks array in state */
    // setLoading(true)

    console.log('in handle submit')
    // sendToFirestore(favLink)
    let oldFavLinks = links

    let newLinks = [...oldFavLinks, favLink]
    console.log('should update the table', newLinks)
    setLinks(newLinks)

    await updateList(user.uid, props.data.id, null, newLinks, null)

    if (rabbitHole.data.published) {
      console.log('should update feed')
      await updateFeed(user.uid, favLink.URL, favLink.tags)
    }
  }

  let publishButton = (
    <button
      className="px-6 py-2.5 bg-gray-200 text-gray-700 font-medium text-xs leading-tight uppercase rounded shadow-md hover:bg-purple-700 hover:text-white hover:shadow-lg transition duration-150 ease-in-out display:block"
      onClick={(e) => {
        e.preventDefault()
        changePublishStatus(user.uid, props.data.id, true, () => {
          // alert('Your changes have been published')
          copyShareLink()
        })
      }}
    >
      Publish
    </button>
  )

  let settingsButton = (
    <button
      className="px-6 py-2.5 bg-gray-200 text-gray-700 font-medium text-xs leading-tight uppercase rounded shadow-md hover:bg-purple-700 hover:text-white hover:shadow-lg transition duration-150 ease-in-out display:block"
      onClick={() => {
        console.log('click')
        props.handleShow(true)
      }}
    >
      Settings
    </button>
  )

  let verifyLinksButton = (
    <button
      className="px-6 py-2.5 bg-gray-200 text-gray-700 font-medium text-xs leading-tight uppercase rounded shadow-md  hover:bg-purple-700 hover:text-white hover:shadow-lg  transition duration-150 ease-in-out display:block"
      onClick={() => {
        console.log('click')
        alert('coming soon!')
      }}
    >
      Verify Links
    </button>
  )

  let copyShareLink = async () => {
    // add to clipboard
    let userName = null
    let rhName = null

    if (userInfo) {
      if (userInfo.userName) {
        userName = userInfo.userName
        console.log('rabbitHole to copy', rabbitHole)
        rhName = rabbitHole.data.name.replace(/\s+/g, '-').toLowerCase()
      }
    }

    let URL = userName
      ? `https://alpha.viberate.tech/u/${userName}/${rhName}`
      : `https://alpha.viberate.tech/to/${user.uid}/${rabbitHole.id}`

    // setShareLink(URL)

    var copyText = document.getElementById('share-url').value

    // /* Select the text field */
    // copyText.select()
    // copyText.setSelectionRange(0, 99999) /* For mobile devices */

    console.log('copy text is', copyText)
    /* Copy the text inside the text field */
    let copy = await navigator.clipboard.writeText(copyText)
    console.log()
    /* Alert the copied text */
    alert('Your link has been published and copied to your clipboard! ')
    // await props.copyShareLink()
  }

  let handleChange = async (newLinks) => {
    await updateList(user.uid, props.data.id, null, newLinks, null)
  }

  let closeVF = () => {
    setShowVF(false)
  }

  let saveVFContent = () => {
    console.log('should save')
  }

  return (
    <>
      {loading && <h1>...loading</h1>}
      {user && !loading && (
        <div className="container my-10 ml-7" key={props.index}>
          {/* <h3>{props.data.id}</h3> */}
          {/* {rabbitHole.data.published == true ? (
        <span onClick={copyShareLink}>Copy Share Link</span>
      ) : (
        publishButton
      )} */}
          <div className="flex-row">
            {verifyLinksButton}
            {publishButton}
            {settingsButton}

            <input
              id="share-url"
              style={{ display: 'none' }}
              value={
                userInfo
                  ? userInfo.userName
                    ? `https://alpha.viberate.tech/u/${
                        userInfo.userName
                      }/${rabbitHole.data.name
                        .replace(/\s+/g, '-')
                        .toLowerCase()}`
                    : `https://alpha.viberate.tech/to/${user.uid}/${rabbitHole.id}`
                  : `https://alpha.viberate.tech/to/${user.uid}/${rabbitHole.id}`
              }
            />
          </div>
          {/* <h1 className="text-3xl font-bold underline">Add to your Rabbit Hole</h1> */}
          <ValidationModal
            show={showVF}
            handleClose={closeVF}
            handleSave={saveVFContent}
          />
          {/*TODO - Add Table Component */}
          <Table
            index={props.index}
            linkData={links}
            removeLink={handleRemove}
            handleChange={handleChange}
            showVFModal={() => {
              setShowVF(true)
            }}
          />
          <br />

          {/* {loading && <h3>Fetching link previews... 🤔🤔🤔</h3>} */}

          <h3>Add New</h3>
          {/*TODO - Add Form Component */}
          <Form submitForm={handleSubmit} />
        </div>
      )}
    </>
  )
}

export default LinkContainer
