import SearchBar from '../components/SearchBar'
import Section from '../components/Section'
import Reviews from '../components/Reviews'
import Ratings from '../components/Ratings'
import { useNavigate } from 'react-router-dom'
import { useEffect, useState } from 'react'
import { useAuthState } from 'react-firebase-hooks/auth'
import { auth } from '../Firebase'

import { fetchFeed } from '../ghettoAPI'

const Feed = (props) => {
  return (
    <div>
      <ul role="list" className="divide-y divide-gray-200">
        {props.updates.map((update, index) => (
          <li key={index} className="py-4">
            <div className="flex space-x-3">
              <div className="flex-1 space-y-1">
                <div className="flex items-center justify-between">
                  <h3 className="text-sm font-medium">{update.user}</h3>
                </div>
                <p className="text-sm text-gray-500">
                  Found{' '}
                  <a href={update.URL} target="_blank" rel="noreferrer">
                    {update.URL}
                  </a>{' '}
                  {update.vibe.join(' and ')}
                </p>
              </div>
            </div>
          </li>
        ))}
      </ul>
    </div>
  )
}
const Card = () => {
  return (
    <a
      href="#"
      class="block p-6 max-w-sm bg-white rounded-lg border border-gray-200 shadow-md hover:bg-gray-100 dark:bg-gray-800 dark:border-gray-700 dark:hover:bg-gray-700"
    >
      <h5 class="mb-2 text-2xl font-bold tracking-tight text-gray-900 dark:text-white">
        Noteworthy technology acquisitions 2021
      </h5>
      {/* <p class="font-normal text-gray-700 dark:text-gray-400">
        Here are the biggest enterprise technology acquisitions of 2021 so far,
        in reverse chronological order.
      </p> */}
    </a>
  )
}

const Dashboard = (props) => {
  let navigate = useNavigate()
  const [updates, setUpdates] = useState(null)
  const [user, loading, error] = useAuthState(auth)

  const fetchFeedUpdates = async () => {
    let updates = await fetchFeed()
    if (updates) setUpdates(updates)
  }

  useEffect(() => {
    // if (loading) {
    //   // maybe trigger a loading screen
    //   return
    // }

    fetchFeedUpdates()
    // console.log(lists)
    // console.log('lists from fb', lists)
    // if (!user) navigate('/')
  }, [])

  return (
    <div
      class="container mx-auto"
      onPaste={(e) => {
        alert(`detected you paste! ${e.clipboardData.getData('Text')}`)
      }}
    >
      {/* <Ratings /> */}
      {/* <SearchBar /> */}
      {false && (
        <div className="my-10">
          {/* <h2 class="mb-2 text-2xl font-bold tracking-tight text-gray-900 dark:text-white">
          {' '}
          Quick Saved{' '}
        </h2> */}
          {/* <div class="container mx-auto justify-center grid grid-rows-3 grid-flow-col gap-3 text-center">
          <Card />
          <Card />
          <Card />
          <Card />
          <Card />
          <Card />
        </div> */}
        </div>
      )}

      <div className="flex flex-col items-center my-10">
        <button
          type="button"
          className="inline-block px-6 py-2.5 bg-purple-600 text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:bg-purple-700 hover:shadow-lg focus:bg-purple-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-purple-800 active:shadow-lg transition duration-150 ease-in-out"
          onClick={() => {
            props.handleShow()
          }}
        >
          Create a new list
        </button>
        {/* <p>or paste a link!</p> */}

        {/* <Reviews /> */}
        {updates && <Feed updates={updates} />}
      </div>
      <div class="flex-col justify-between">
        {/* <Section title={'Discover New Sources'} data={[1, 2, 3, 4]} />
        <Section title={'Curated For You'} data={[1, 2, 3, 4]} /> */}
      </div>
    </div>
  )
}

export default Dashboard
