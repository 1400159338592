import React, { useState, useRef } from 'react'
import Microlink from '@microlink/react'
import Select from 'react-select'
import { GroupedOptions } from '../data/Options'
import moment from 'moment'

// const TableHeader = () => {
//   // boilerplate table header functional component
//   return (
//     <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
//       <tr>
//         {/* <th scope="col" className="px-6 py-3">
//           Verified
//         </th> */}
//         <th scope="col" className="px-6 py-3">
//           URL
//         </th>
//         <th scope="col" className="px-6 py-3">
//           Note
//         </th>
//         <th scope="col" className="px-6 py-3">
//           Vibe
//         </th>
//         {/* <th scope="col" className="px-6 py-3">
//           Last Modified
//         </th> */}
//         <th scope="col" class="px-6 py-3">
//           Edit
//         </th>
//         <th scope="col" className="px-6 py-3">
//           Remove
//         </th>
//       </tr>
//     </thead>
//   )
// }

// const TableBody = (props) => {
//   // boilerplate table body functional component
//   // we use Array.map to create table rows from LinkData passed via props
//   const [editIndex, setEditIndex] = useState(-1)
//   const [tags, setTags] = useState(null)
//   const [date, setDate] = useState()

//   const captionRef = useRef()
//   const urlRef = useRef()
//   const tagRef = useRef()

//   let handleDelete = (e) => {
//     console.log(props.linkData)
//     let newLinks = props.linkData.splice(e.target.id, 1)
//     console.log(newLinks)
//     props.handleChange(newLinks)
//   }

//   let startEditing = (event) => {}

//   let handleSubmit = (event) => {}

//   let handleClick = (event) => {
//     if (event.target.id == -1) {
//       if (
//         urlRef.current.value === '' &&
//         captionRef.current.value === '' &&
//         tags == null
//       ) {
//         console.log('same')
//       } else if (
//         urlRef.current.value !== props.linkData[editIndex].URL ||
//         captionRef.current.value !== props.linkData[editIndex].caption ||
//         tags !== props.linkData[editIndex].tags
//       ) {
//         let newLinks = props.linkData
//         if (urlRef.current.value !== '') {
//           newLinks[editIndex].URL = urlRef.current.value
//         }
//         if (captionRef.current.value !== '') {
//           newLinks[editIndex].caption = captionRef.current.value
//         }
//         if (tags !== null) {
//           newLinks[editIndex].tags = tags
//         }
//         props.linkData[editIndex].date = moment().format()

//         props.handleChange(newLinks)
//       }
//     } else {
//       setTags(null)
//     }
//     setEditIndex(event.target.id)
//   }

//   let renderTags = (_tags) => {
//     return _tags.map((tag, index) => {
//       return (
//         <span
//           key={index}
//           className="inline-flex items-center px-2.5 py-0.5 rounded-md text-sm font-medium bg-purple-100 text-purple-800"
//         >
//           {tag.label}
//         </span>
//       )
//     })
//   }

//   let onChange = (newValue, actionMeta) => {
//     setTags(newValue)
//   }

//   const rows = props.linkData.map((row, index) => {
//     return (
//       <tr
//         key={index}
//         class="bg-white border-b dark:bg-gray-800 dark:border-gray-700"
//       >
//         {/* <td className="px-12 py-4">
//           <button
//             style={{
//               backgroundColor: '#2d2d2d',
//               border: 'none',
//               color: '#2d2d2d',
//               width: '25px',
//               height: '25px',
//               textAlign: 'center',
//               textDecoration: 'none',
//               display: 'inline-block',
//               fontSize: '16px',
//               borderRadius: '100%',
//             }}
//             onClick={props.showVerification}
//           ></button>
//         </td> */}
//         <td className="px-12 py-4">
//           {index == editIndex ? (
//             <input
//               type="text"
//               name="URL"
//               placeholder={row.URL}
//               ref={urlRef}
//               className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
//             />
//           ) : (
//             <Microlink size="small" url={row.URL} />
//             // row.URL
//           )}
//         </td>
//         <td className="px-6 py-4">
//           {index == editIndex ? (
//             <input
//               type="text"
//               name="caption"
//               placeholder={row.caption}
//               ref={captionRef}
//               className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
//             />
//           ) : (
//             row.caption
//           )}
//         </td>
//         <td className="px-6 py-4">
//           {index == editIndex ? (
//             <Select
//               isMulti
//               placeholder="Tag as many as you'd like!"
//               options={GroupedOptions}
//               onChange={onChange}
//               ref={tagRef}
//               value={tags}
//               // onInputChange={handleInputChange}
//             />
//           ) : (
//             renderTags(row.tags)
//           )}
//         </td>
//         {/* <td className="px-6 py-4">{date}</td> */}

//         <td className="px-6 py-4">
//           {index == editIndex ? (
//             <span
//               href="#"
//               id={-1}
//               className="font-medium text-blue-600 dark:text-blue-500 hover:underline"
//               onClick={handleClick}
//             >
//               Save
//             </span>
//           ) : (
//             <span
//               href="#"
//               id={index}
//               className="font-medium text-blue-600 dark:text-blue-500 hover:underline"
//               onClick={handleClick}
//             >
//               Edit
//             </span>
//           )}
//         </td>
//         <td className="px-6 py-4">
//           {/* // <button
//           //   onClick={(e) => {
//           //     e.preventDefault()
//           //     props.removeLink(index)
//           //   }}
//           // >
//           //   Delete
//           // </button> */}
//           <a href="#" id={index} onClick={handleDelete}>
//             Delete
//           </a>
//         </td>
//       </tr>
//     )
//   })

//   return <tbody>{rows}</tbody>
// }

const TableHeader2 = () => {
  // boilerplate table header functional component
  return (
    <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
      <tr>
        {/* <th scope="col" className="px-6 py-3">
          Verified
        </th> */}
        <th scope="col" className="px-6 py-3">
          Link
        </th>
        {/* <th scope="col" className="px-6 py-3">
          Last Modified
        </th> */}
        <th scope="col" class="px-6 py-3">
          Edit
        </th>
        <th scope="col" className="px-6 py-3">
          Remove
        </th>
      </tr>
    </thead>
  )
}

const TableBody2 = (props) => {
  // boilerplate table body functional component
  // we use Array.map to create table rows from LinkData passed via props
  const [editIndex, setEditIndex] = useState(-1)
  const [tags, setTags] = useState(null)
  const [date, setDate] = useState()

  const captionRef = useRef()
  const urlRef = useRef()
  const tagRef = useRef()

  let handleDelete = (e) => {
    console.log(props.linkData)
    let newLinks = props.linkData.splice(e.target.id, 1)
    console.log(newLinks)
    props.handleChange(newLinks)
  }

  let startEditing = (event) => {}

  let handleSubmit = (event) => {}

  let handleClick = (event) => {
    if (event.target.id == -1) {
      if (
        urlRef.current.value === '' &&
        captionRef.current.value === '' &&
        tags == null
      ) {
        console.log('same')
      } else if (
        urlRef.current.value !== props.linkData[editIndex].URL ||
        captionRef.current.value !== props.linkData[editIndex].caption ||
        tags !== props.linkData[editIndex].tags
      ) {
        let newLinks = props.linkData
        if (urlRef.current.value !== '') {
          newLinks[editIndex].URL = urlRef.current.value
        }
        if (captionRef.current.value !== '') {
          newLinks[editIndex].caption = captionRef.current.value
        }
        if (tags !== null) {
          newLinks[editIndex].tags = tags
        }
        props.linkData[editIndex].date = moment().format()

        props.handleChange(newLinks)
      }
    } else {
      setTags(null)
    }
    setEditIndex(event.target.id)
  }

  let renderTags = (_tags) => {
    return _tags.map((tag, index) => {
      return (
        <span
          key={index}
          className="inline-flex items-center px-2.5 py-0.5 rounded-md text-sm font-medium bg-purple-100 text-purple-800"
        >
          {tag.label}
        </span>
      )
    })
  }

  let onChange = (newValue, actionMeta) => {
    setTags(newValue)
  }

  const rows = props.linkData.map((row, index) => {
    return (
      <tr
        key={index}
        class="bg-white border-b dark:bg-gray-800 dark:border-gray-700"
      >
        {/* <td className="px-12 py-4">
          <button
            style={{
              backgroundColor: '#2d2d2d',
              border: 'none',
              color: '#2d2d2d',
              width: '25px',
              height: '25px',
              textAlign: 'center',
              textDecoration: 'none',
              display: 'inline-block',
              fontSize: '16px',
              borderRadius: '100%',
            }}
            onClick={props.showVerification}
          ></button>
        </td> */}
        <td className="px-12 py-4 flex">
          <div className="w-full flex flex-col">
            {index == editIndex ? (
              <form>
                <label
                  for="name"
                  className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                >
                  URL
                </label>
                <input
                  type="text"
                  name="URL"
                  placeholder={row.URL}
                  ref={urlRef}
                  className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                />
              </form>
            ) : (
              <Microlink size="small" url={row.URL} />
              // row.URL
            )}
            {index == editIndex ? (
              <form>
                <label
                  for=""
                  className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300 mt-6"
                >
                  Caption
                </label>
                <input
                  type="text"
                  name="caption"
                  placeholder={row.caption}
                  ref={captionRef}
                  className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                />
              </form>
            ) : (
              <div className="mt-6">{row.caption}</div>
            )}
            <div className="mt-6 flex-row">
              {index == editIndex ? (
                <form>
                  <label
                    for="name"
                    className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                  >
                    Vibe
                  </label>
                  <Select
                    isMulti
                    placeholder="Tag as many as you'd like!"
                    options={GroupedOptions}
                    onChange={onChange}
                    ref={tagRef}
                    value={tags}
                    // onInputChange={handleInputChange}
                  />
                </form>
              ) : (
                renderTags(row.tags)
              )}
            </div>
          </div>
        </td>
        {/* <td className="px-6 py-4">{date}</td> */}
        <td className="px-6 py-4">
          {index == editIndex ? (
            <span
              href="#"
              id={-1}
              className="font-medium text-blue-600 dark:text-blue-500 hover:underline"
              onClick={handleClick}
            >
              Save
            </span>
          ) : (
            <span
              href="#"
              id={index}
              className="font-medium text-blue-600 dark:text-blue-500 hover:underline"
              onClick={handleClick}
            >
              Edit
            </span>
          )}
        </td>
        <td className="px-6 py-4">
          {/* // <button
          //   onClick={(e) => {
          //     e.preventDefault()
          //     props.removeLink(index)
          //   }}
          // >
          //   Delete
          // </button> */}
          <a href="#" id={index} onClick={handleDelete}>
            Delete
          </a>
        </td>
      </tr>
    )
  })

  return <tbody>{rows}</tbody>
}

const Table = (props) => {
  const { linkData, handleChange, showVFModal } = props
  {
    /*TODO - return <table> component, TableHeader and TableBody  */
    return (
      <table
        className="w-full text-sm text-left text-gray-500 dark:text-gray-400"
        key={props.index}
      >
        <TableHeader2 />
        <TableBody2
          showVerification={showVFModal}
          linkData={linkData ? linkData : []}
          handleChange={handleChange}
        />
      </table>
    )
  }
}

export default Table
