//React
import React from 'react'

//Components
import NavBar from './components/NavBar'

//Style imports
// import './css/App.css'

import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'

//Pages
import Auth from './pages/Auth'
import Login from './pages/Login'
import Register from './pages/Register'
import Subscribe from './pages/Subscribe'
import Dashboard from './pages/Dashboard'
import Profile from './pages/Profile'
import RBBT from './pages/RBBT'
import LiveView from './pages/LiveView'
import 'bootstrap/dist/css/bootstrap.min.css'
// const Auth = () => {
//   return <h1 className="text-3xl font-bold underline">Auth</h1>
// }

// const Landing = () => {
//   return <h1 className="text-3xl font-bold underline">Landing</h1>
// }

const Search = () => {
  return <h1 className="text-3xl font-bold underline">Search</h1>
}

function App() {
  //localStorage.clear();
  return (
    <>
      <Router>
        {/* <NavBar className="navBar" /> */}
        <Routes>
          <Route exact path="/" element={<Auth />} />
          {/* <Route exact path="/login" element={<Login />} /> */}
          <Route exact path="/register" element={<Register />} />

          <Route exact path="/profile" element={<Profile />} />

          <Route exact path="/dashboard" element={<RBBT />} />

          <Route exact path="/subscribe" element={<Subscribe />} />

          <Route
            exact
            path="/u/:userName/:rhName"
            element={<LiveView fetchUser={true} />}
          ></Route>

          <Route exact path="/to/:uid/:listId" element={<LiveView />}></Route>

          {/* 
          <Route exact path="/add" element={<Add />} />
          <Route exact path="/search" element={<Search />} />
          <Route exact path="/profile" element={<Profile />} /> */}
        </Routes>
      </Router>
    </>
  )
}
export default App
