const ValidityOptions = [
  { value: 'trustworthy', label: 'Trustworthy 🔒', rating: 5 },
  { value: 'untrustworthy', label: 'Untrustworthy 🔓', rating: 1 },
  //   { value: 'idk-validity', label: 'Not sure 🤷🏿‍♂️ ', rating: 5 },
  // { value: 'found-online', label:'Found Online 💻', rating: 3},
  // { value: ''}
]

const IntentOptions = [
  { value: 'share', label: 'Meant to share 🗣 ', rating: 5 },
  { value: 'save', label: 'Just to save 😅 ', rating: 5 },
  { value: 'read-later', label: 'Read Later 🕓 ', rating: 5 },
  //   { value: 'idk-intent', label: 'Not sure 🤷🏿‍♂️ ', rating: 5 },
]

const InterestOptions = [
  { value: 'interesting', label: 'Interesting 🧐', rating: 5 },
  { value: 'uninteresting', label: 'Uninteresting 😑', rating: 1 },
  { value: 'funny', label: 'Funny 😂', rating: 3 },
  { value: 'serious', label: 'Serious 😐', rating: 3 },
  { value: 'thought-provoking', label: 'Thought Provoking 🤔', rating: 3 },
  { value: 'shocking', label: 'Shocking 😧', rating: 3 },
  { value: 'saddening', label: 'Saddening 😢', rating: 3 },
  { value: 'upsetting', label: 'Upsetting 😠', rating: 3 },
  //   { value: 'idk-interest', label: 'Not sure 🤷🏿‍♂️ ', rating: 5 },
]

const BackgroundOptions = [
  { value: 'mine', label: 'This is my link 👋🏿', rating: 1 },
  { value: 'about-me', label: 'This is about me 🤝', rating: '5' },
  { value: 'found-online', label: 'Found this online 🔎 ', rating: 5 },
  { value: 'referral', label: 'Heard from someone else 👂 ', rating: 5 },
  //   { value: 'idk', label: 'Not sure 🤷🏿‍♂️ ', rating: 5 },
]

const BiasOptions = [
  { value: 'biased', label: 'Biased 👈', rating: 1 },
  { value: 'unbiased', label: 'Unbiased 👐', rating: '5' },
  //   { value: 'idk-bias', label: 'Not sure 🤷🏿‍♂️ ', rating: '5' },
]

export const GroupedOptions = [
  // {
  //   label: 'Validity',
  //   options: ValidityOptions,
  // },
  // {
  //   label: 'Intent',
  //   options: IntentOptions,
  // },
  {
    label: 'Interest',
    options: InterestOptions,
  },
  // {
  //   label: 'Background',
  //   options: BackgroundOptions,
  // },
  // {
  //   label: 'Bias',
  //   options: BiasOptions,
  // },
]
export const Purposes = [
  {
    value: 'For myself',
    label: 'For myself',
  },
  {
    value: 'For fun',
    label: 'For fun',
  },
  {
    value: 'For school',
    label: 'For school',
  },
  {
    value: 'For work',
    label: 'For work',
  },
  {
    value: 'For research',
    label: 'For research',
  },
]
