import React, { useState } from 'react'
import getUrls from 'get-urls'
import Select from 'react-select'
import CreatableSelect from 'react-select/creatable'

import { GroupedOptions } from '../data/Options'
import moment from 'moment'
import Collapse from 'react-bootstrap/Collapse'

const Form = (props) => {
  const [name, setName] = useState('')
  const [caption, setCaption] = useState('')
  const [URL, setURL] = useState('')
  const [tags, setTags] = useState([])
  const [hashtags, setHashtags] = useState([])
  const [captionOpen, setCaptionOpen] = useState(false)
  const [hashtagsOpen, setHashtagsOpen] = useState(false)

  let handleChange = (event) => {
    /* TODO - Logic for changing state based on form changes */
    event.target.name === 'name'
      ? setName(event.target.value)
      : setURL(event.target.value)
  }

  let onChange = (newValue, actionMeta) => {
    console.group('Value Changed')
    console.log(newValue)
    setTags(newValue)
    console.log(`action: ${actionMeta.action}`)
    console.groupEnd()
  }

  let hashtagChange = (newValue, actionMeta) => {
    console.group('Value Changed')
    console.log(newValue)
    setHashtags([...hashtags, newValue])
    console.log(`action: ${actionMeta.action}`)
    console.groupEnd()
  }
  let hashtagInputChange = (inputValue, actionMeta) => {
    console.group('Input Changed')
    console.log(inputValue)
    console.log(`action: ${actionMeta.action}`)
    console.groupEnd()
  }
  // let handleInputChange = (inputValue, actionMeta) => {
  //   console.group('Input Changed')
  //   console.log(inputValue)
  //   console.log(`action: ${actionMeta.action}`)
  //   console.groupEnd()
  // }

  let onFormSubmit = (event) => {
    // to prevent page reload on form submit
    console.log('event', event)
    console.log('form submitted')
    event.preventDefault()
    /* TODO - Logic for calling props to handle submission and setting state changes */
    if (URL === '') {
      alert('Please do not submit an empty URL field.')
    } else {
      let urlData = getUrls(URL)
      console.log({ caption, URL, tags, date: moment().format() })
      props.submitForm({ caption, URL, tags, date: moment().format() })
      setName('')
      setCaption('')
      setHashtags([])
      setURL('')
    }
  }

  return (
    <div className="w-3/5 ">
      <form>
        {/* TODO - Logic for returning a form element with labels and inputs for link name and URL */}
        <div className="mb-6">
          <label
            for="URL"
            className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300"
          >
            URL
          </label>
          <input
            type="text"
            name="URL"
            value={URL}
            onChange={handleChange}
            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
          />
        </div>
        <div className="mb-6">
          <label
            for="name"
            className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300"
          >
            What's the vibe?
          </label>
          <Select
            isMulti
            placeholder="Tag as many as you'd like!"
            options={GroupedOptions}
            onChange={onChange}
            // onInputChange={handleInputChange}
          />
        </div>
        <div className="mb-6">
          <button
            className="inline-flex items-center px-3 py-2 border border-transparent text-sm leading-4 font-medium rounded-md text-indigo-700 bg-indigo-100 hover:bg-indigo-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
            onClick={(e) => {
              e.preventDefault()
              setCaptionOpen(!captionOpen)
            }}
            aria-controls="example-collapse-text"
            aria-expanded={captionOpen}
            style={{ display: captionOpen ? 'none' : 'block' }}
          >
            Add a Note
          </button>
          <Collapse in={captionOpen}>
            <div id="example-collapse-text">
              <label
                for="name"
                className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300"
              >
                Caption this link
              </label>
              <textarea
                name="caption"
                value={caption}
                onChange={(e) => {
                  setCaption(e.target.value)
                }}
                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              ></textarea>
            </div>
          </Collapse>
        </div>
        <div className="mb-6">
          <button
            className="inline-flex items-center px-3 py-2 border border-transparent text-sm leading-4 font-medium rounded-md text-indigo-700 bg-indigo-100 hover:bg-indigo-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
            onClick={(e) => {
              e.preventDefault()
              setHashtagsOpen(!hashtagsOpen)
            }}
            aria-controls="example-collapse-text"
            aria-expanded={hashtagsOpen}
            style={{ display: hashtagsOpen ? 'none' : 'block' }}
          >
            Add Hashtags to Search
          </button>
          <Collapse in={hashtagsOpen}>
            <div id="example-collapse-text">
              <label
                for="name"
                className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300"
              >
                Add some hashtags
              </label>
              <CreatableSelect
                isClearable
                isMulti
                onChange={hashtagChange}
                onInputChange={hashtagInputChange}
              />
            </div>
          </Collapse>
        </div>

        {/* <input
            type="text"
            name="name"
            value={name}
            onChange={handleChange}
            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
          /> */}

        <button
          className="inline-block px-6 py-2.5 bg-purple-600 text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:bg-purple-700 hover:shadow-lg focus:bg-purple-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-purple-800 active:shadow-lg transition duration-150 ease-in-out"
          onClick={onFormSubmit}
        >
          Submit
        </button>
      </form>
    </div>
  )
}

export default Form
