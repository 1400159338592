import { SET_LISTS } from '../actions/types'

const listReducer = (state = { lists: null }, action) => {
  switch (action.type) {
    case SET_LISTS:
      return { ...state, lists: action.payload }
    default:
      return state
  }
}
export default listReducer
