import {
  doc,
  query,
  getDocs,
  collection,
  where,
  addDoc,
  setDoc,
  deleteDoc,
  updateDoc,
  getDoc,
} from 'firebase/firestore'

import { db } from './Firebase'
// List

import moment from 'moment'

export const fetchUserFromName = async (userName) => {
  if (userName) {
    try {
      const userRef = doc(db, 'usernames', userName)
      const docSnap = await getDoc(userRef)
      if (docSnap.exists()) {
        // console.log("Document data:", docSnap.data());
        return docSnap.data().uid
      } else {
        return null
      }
    } catch (e) {
      return null
    }
  } else {
    return null
  }
}

export const fetchProfile = async (uid) => {
  if (uid) {
    try {
      const userRef = doc(db, 'users', uid)
      const docSnap = await getDoc(userRef)
      if (docSnap.exists()) {
        // console.log("Document data:", docSnap.data());

        return docSnap.data()
      } else {
        return null
      }
    } catch (e) {
      return null
    }
  } else {
    console.log('user is not signed in')
    return null
  }
}

export const updateProfile = async (uid, displayName, userName) => {
  if (uid) {
    try {
      const userRef = doc(db, 'users', uid)

      console.log('... user ref', userRef)

      let data = {
        lastUpdated: moment().format(),
      }

      if (displayName) {
        data.displayName = displayName
      }

      if (userName) {
        // check if userName exists
        console.log('...in userName logic')
        const usernameRef = doc(db, 'usernames', `${userName}`)

        const docSnap = await getDoc(usernameRef)

        if (docSnap.exists()) {
          console.log('Document data:', docSnap.data())
          if (docSnap.data().uid !== uid) {
            alert('This username is already taken')
            return
          }
        } else {
          // doc.data() will be undefined in this case
          data.userName = userName

          // find the document with the old uid and delete it
          const usernames = collection(db, 'usernames')
          console.log('in usernames logic')
          // Create a query against the collection.
          const q = query(usernames, where('uid', '==', uid))
          const querySnapshot = await getDocs(q)
          querySnapshot.forEach(async (_doc) => {
            console.log('in usernames delete logic')
            // doc.data() is never undefined for query doc snapshots

            await deleteDoc(_doc)
          })

          console.log('should be setting the username doc')

          await setDoc(doc(db, 'usernames', `${userName}`), {
            uid: uid,
          })

          alert(`Welcome to the party ${userName}!`)
        }
      }

      console.log('...data', data)

      await updateDoc(userRef, data)
    } catch (e) {
      console.error('Error updating user: ', e)
    }
  } else {
    alert('You need to log in first!')
  }
}

export const createList = async (uid, listName, purpose) => {
  //getUserID
  console.log('....creating list in firebase')
  if (uid) {
    try {
      let docRefid = await addDoc(
        collection(db, 'users', `${uid}/rabbitHoles`),
        {
          name: listName,
          created: moment().format(),
          purpose: purpose,
          links: [],
          published: false,
        },
      ).then((docRef) => {
        console.log('Document written with ID: ', docRef.id)
        return docRef.id
      })
      return docRefid
    } catch (e) {
      console.error('Error adding document: ', e)
    }
  } else {
    alert('You need to log in first!')
  }
}

export const fetchLists = async (uid) => {
  if (uid) {
    try {
      let lists = []
      const querySnapshot = await getDocs(
        collection(db, 'users', `${uid}/rabbitHoles`),
      )

      querySnapshot.forEach((doc) => {
        // doc.data() is never undefined for query doc snapshots
        lists.push({ id: doc.id, data: doc.data() })
      })

      return lists
    } catch (e) {
      console.error('Error fetching lists: ', e)
    }
  } else {
    alert('You need to log in first!')
  }
}

export const fetchListFromName = async (uid, name) => {
  if (uid) {
    try {
      let lists = []
      const rabbitHoles = collection(db, 'users', `${uid}/rabbitHoles`)
      const q = query(rabbitHoles, where('name', '==', `${name}`))
      const querySnapshot = await getDocs(q)

      querySnapshot.forEach((doc) => {
        // doc.data() is never undefined for query doc snapshots
        lists.push({ id: doc.id, data: doc.data() })
      })

      return lists[0]
    } catch (e) {
      console.error('Error fetching lists: ', e)
    }
  } else {
    alert('You need to log in first!')
  }
}

export const updateList = async (uid, listId, newName, links, purpose) => {
  if (uid) {
    try {
      const listRef = doc(db, 'users', `${uid}/rabbitHoles/${listId}`)

      console.log('... list ref', listRef)

      let data = {
        lastUpdated: moment().format(),
      }

      if (newName) {
        data.name = newName
      }

      if (links) {
        data.links = links
      }

      if (purpose) {
        data.purpose = purpose
      }

      console.log('...data', data)

      await updateDoc(listRef, data)
    } catch (e) {
      console.error('Error updating list: ', e)
    }
  } else {
    // alert('You need to log in first!')
  }
}

export const changePublishStatus = async (uid, listId, status, callback) => {
  if (uid) {
    try {
      const listRef = doc(db, 'users', `${uid}/rabbitHoles/${listId}`)

      console.log('... list ref', listRef)

      let data = {
        published: status,
        lastUpdated: moment().format(),
      }

      await updateDoc(listRef, data)
      console.log('list published:', status)
      callback()
    } catch (e) {
      console.error('Error updating list: ', e)
    }
  } else {
    alert('You need to log in first!')
  }
}

export const readPublicList = async (uid, listId) => {
  console.log('...reading public list')
  if (uid) {
    try {
      const docSnap = await getDoc(
        doc(db, 'users', `${uid}/rabbitHoles/${listId}`),
      )

      if (docSnap.exists()) {
        console.log('Document data:', docSnap.data())
        return docSnap.data()
      } else {
        // doc.data not defined
        console.log('Problem reading public list')
        return null
      }
    } catch (e) {
      alert('there was an error reading public list')
      console.error(e)
    }
  }
}

export const deleteList = async (uid, listId) => {
  if (uid) {
    try {
      await deleteDoc(doc(db, 'users', `${uid}/rabbitHoles/${listId}`))
    } catch (e) {
      console.error('Error deleting doc')
    }
  } else {
    alert('You need to log in first!')
  }
}

export const updateFeed = async (uid, URL, tags) => {
  if (uid) {
    console.log('updating feed')
    let date = moment().format('YYYY-MM-DD')
    try {
      // fetch userName
      let userInfo = await fetchProfile(uid)
      let user = 'A User'
      if (userInfo.userName) {
        user = userInfo.userName
      } else if (userInfo.displayName) {
        user = userInfo.displayName
      }

      console.log('user is', user)

      let docRefid = await addDoc(collection(db, 'feed', `${date}/updates`), {
        user,
        interests: tags,
        URL,
      }).then((docRef) => {
        console.log('Feed update written with ID: ', docRef.id)
        return docRef.id
      })
      return docRefid
    } catch (e) {
      console.error('Error updating feed: ', e)
    }
  } else {
    return null
  }
}

export const fetchFeed = async () => {
  try {
    console.log('fetching feed')
    let date = moment().format('YYYY-MM-DD')
    let updates = []
    const querySnapshot = await getDocs(collection(db, 'feed', date, 'updates'))

    console.log('found feed collection', querySnapshot)

    querySnapshot.forEach((doc) => {
      console.log(doc.data())
      let tagLabels = doc.data().interests.map((tag) => {
        return tag.label
      })
      // doc.data() is never undefined for query doc snapshots
      updates.push({
        user: doc.data().user,
        URL: doc.data().URL,
        vibe: tagLabels,
      })
    })

    return updates
  } catch (e) {
    console.error('Error fetching feed: ', e)
  }
}
