import { SAVE_USER_ID } from '../actions/types'

const userReducer = (state = {}, action) => {
  switch (action.type) {
    case SAVE_USER_ID:
      return { ...state, uid: action.payload }
    default:
      return state
  }
}
export default userReducer
