import { Fragment, useState, useEffect, useRef } from 'react'
import { Dialog, Menu, Transition } from '@headlessui/react'
import {
  BellIcon,
  CalendarIcon,
  ChartBarIcon,
  FolderIcon,
  HomeIcon,
  InboxIcon,
  MenuAlt2Icon,
  UsersIcon,
  FolderAddIcon,
  XIcon,
} from '@heroicons/react/outline'
import { SearchIcon } from '@heroicons/react/solid'
import LinkContainer from '../components/LinkContainer'

import { Link, useNavigate } from 'react-router-dom'
import { auth, logout } from '../Firebase'
import { useAuthState } from 'react-firebase-hooks/auth'

import { useDispatch, useSelector } from 'react-redux'

import { saveRabbitHoles } from '../actions'

import Dashboard from './Dashboard'
import Profile from './Profile'
import ProfileImage from '../assets/user-male-circle.png'

import {
  createList,
  fetchLists,
  updateList,
  fetchProfile,
  changePublishStatus,
} from '../ghettoAPI'

import { Modal, Button } from 'react-bootstrap'
import { Purposes } from '../data/Options'

import Select from 'react-select'
import { update } from 'lodash'

export default function RBBT() {
  const navigate = useNavigate()

  const DASH = { name: 'Dashboard', href: '#', icon: HomeIcon, current: true }
  const PROFILE = {
    name: 'Profile',
    href: '#',
    icon: UsersIcon,
    current: false,
  }
  const navigation = [
    { name: 'Dashboard', href: '#', icon: HomeIcon, current: true },
    //   { name: 'Team', href: '#', icon: UsersIcon, current: false },
    { name: 'Creative Inspo', href: '#', icon: FolderIcon, current: false },
    { name: 'School', href: '#', icon: FolderIcon, current: false },

    { name: 'Biz Dev', href: '#', icon: FolderIcon, current: false },
    { name: 'Memes', href: '#', icon: FolderIcon, current: false },
    { name: 'News', href: '#', icon: FolderIcon, current: false },

    //   { name: 'Calendar', href: '#', icon: CalendarIcon, current: false },
    //   { name: 'Documents', href: '#', icon: InboxIcon, current: false },
    // { name: 'Add New', href: '#', icon: FolderAddIcon, current: false },
  ]
  const userNavigation = [
    {
      name: 'Your Profile',
      href: '#',
      action: () => {
        setNavTitle('Profile')
        setCurrentItem(-1)
        // navigate('/profile')
      },
    },
    // { name: 'Settings', href: '#' },
    {
      name: 'Sign out',
      href: '#',
      action: (e) => {
        logout()
        navigate('/')
      },
    },
  ]

  function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
  }

  const [user, loading, error] = useAuthState(auth)

  const dispatch = useDispatch()

  // const userReducer = useSelector((state) => state.user)
  // const UID = userReducer.uid
  // console.log('UID', UID)

  const [sidebarOpen, setSidebarOpen] = useState(false)

  const [currentItem, setCurrentItem] = useState(0)

  const [navTitle, setNavTitle] = useState('Dashboard')

  const [categories, setCategories] = useState([DASH])

  const [newCategory, setNewCategory] = useState('')

  const [addNew, setAddNew] = useState(false)
  const [editCat, setEditCat] = useState(false)
  //Modal code
  const [show, setShow] = useState(false)
  const [purpose, setPurpose] = useState([])

  const [published, setPublished] = useState(false)

  const [shareLink, setShareLink] = useState('')
  const [userInfo, setUserInfo] = useState(null)

  const nameRef = useRef()

  const handleSave = async () => {
    if (purpose != '' && nameRef.current.value != '') {
      setAddNew(false)
      if (!editCat) {
        let newId = await createList(user.uid, newCategory, purpose)
        let newItem = {
          name: newCategory,
          id: newId,
          href: '#',
          icon: FolderIcon,
          current: true,
        }
        let newCategories = [...categories, newItem]
        let index = newCategories.length - 1
        let lists = await fetchLists(user.uid)
        dispatch(saveRabbitHoles(lists))
        setCategories(newCategories)
        setCurrentItem(index)
      } else {
        await updateList(
          user.uid,
          categories[currentItem].id,
          nameRef.current.value,
          null,
          purpose,
        )
        let lists = await fetchLists(user.uid)
        dispatch(saveRabbitHoles(lists))
        // lists = lists.map((item) => {
        //   return {
        //     name: item.data.name,
        //     id: item.id,
        //     href: '#',
        //     icon: FolderIcon,
        //     current: false,
        //     purpose:item.data.purpose
        //   }
        // })
        categories[currentItem].name = nameRef.current.value
        categories[currentItem].purpose = purpose
        setCategories(categories)
      }

      setNavTitle(newCategory)
    }
    setEditCat(false)
    setShow(false)
  }

  const handleShow = (check) => {
    if (check) {
      setEditCat(true)
    }
    console.log('hello')
    setShow(true)
  }

  const handleClose = () => {
    setEditCat(false)
    setShow(false)
  }

  const selectNavItem = (index) => {
    console.log('This is the index: ' + index)

    setCurrentItem(index)
    setNavTitle(categories[index].name)
  }

  let onChange = (newValue) => {
    setPurpose(newValue)
  }
  useEffect(() => {
    if (loading) {
      console.log('loading')
      return
    }
    // if (user) alert('signed in!')
    let fetchData = async (user_uid) => {
      let userInfo = await fetchProfile(user.uid)
      if (userInfo) {
        setUserInfo(userInfo)
      }

      let lists = await fetchLists(user.uid)
      dispatch(saveRabbitHoles(lists))
      lists = lists.map((item) => {
        return {
          name: item.data.name,
          id: item.id,
          href: '#',
          icon: FolderIcon,
          current: false,
        }
      })

      if (categories.length == 1) {
        setCategories([...categories, ...lists])
      }
      return lists
    }

    if (user && !loading) {
      console.log(user.uid)
      fetchData(user.uid)
    }
    // console.log(lists)
    // console.log('lists from fb', lists)
    if (!user) navigate('/')
  }, [user, loading])

  let copyShareLink = async () => {
    // add to clipboard
    let userName = null
    let rhName = null

    if (userInfo.userName) {
      userName = userInfo.userName
      rhName = categories[currentItem].name.replace(/\s+/g, '-').toLowerCase()
    }

    let URL = userName
      ? `https://rabbithole-51919.web.app/u/${userName}/${rhName}`
      : `https://rabbithole-51919.web.app/to/${user.uid}/${categories[currentItem].id}`

    setShareLink(URL)

    var copyText = document.getElementById('share-url').value

    // /* Select the text field */
    // copyText.select()
    // copyText.setSelectionRange(0, 99999) /* For mobile devices */

    console.log('copy text is', copyText)
    /* Copy the text inside the text field */
    let copy = await navigator.clipboard.writeText(copyText)
    console.log()
    /* Alert the copied text */
    alert('Your link has been published amd copied to your clipboard! ')
  }

  let setPublishStatus = (status) => {
    setPublished(status)
  }

  let publishButton = published ? (
    <span
      onClick={async () => {
        let userInfo = await fetchProfile(user.uid)
        let userName = null
        let rhName = null

        if (userInfo.userName) {
          userName = userInfo.userName
          rhName = categories[currentItem].name
            .replace(/\s+/g, '-')
            .toLowerCase()
        }

        let URL = userName
          ? `https://rabbithole-51919.web.app/u/${userName}/${rhName}`
          : `https://rabbithole-51919.web.app/to/${user.uid}/${categories[currentItem].id}`

        setShareLink(URL)
        await copyShareLink()
      }}
    >
      Copy Share Link
    </span>
  ) : (
    <button
      className="text-white bg-[#F26A32] w-1/3 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center mr-3 md:mr-0 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-80"
      onClick={async (e) => {
        e.preventDefault()
        changePublishStatus(user.uid, categories[currentItem].id, true, () => {
          console.log('success')
        })
        await copyShareLink()
      }}
    >
      Publish
    </button>
  )

  return (
    <>
      {loading && <h1>...loading</h1>}
      {user && !loading && (
        <div>
          <Transition.Root show={sidebarOpen} as={Fragment}>
            <Dialog
              as="div"
              className="relative z-40 md:hidden"
              onClose={setSidebarOpen}
            >
              <Transition.Child
                as={Fragment}
                enter="transition-opacity ease-linear duration-300"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="transition-opacity ease-linear duration-300"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <div className="fixed inset-0 bg-gray-600 bg-opacity-75" />
              </Transition.Child>

              <div className="fixed inset-0 z-40 flex">
                <Transition.Child
                  as={Fragment}
                  enter="transition ease-in-out duration-300 transform"
                  enterFrom="-translate-x-full"
                  enterTo="translate-x-0"
                  leave="transition ease-in-out duration-300 transform"
                  leaveFrom="translate-x-0"
                  leaveTo="-translate-x-full"
                >
                  <Dialog.Panel className="relative max-w-xs w-full bg-white pt-5 pb-4 flex-1 flex flex-col">
                    <Transition.Child
                      as={Fragment}
                      enter="ease-in-out duration-300"
                      enterFrom="opacity-0"
                      enterTo="opacity-100"
                      leave="ease-in-out duration-300"
                      leaveFrom="opacity-100"
                      leaveTo="opacity-0"
                    >
                      <div className="absolute top-0 right-0 -mr-12 pt-2">
                        <button
                          type="button"
                          className="ml-1 flex items-center justify-center h-10 w-10 rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
                          onClick={() => setSidebarOpen(false)}
                        >
                          <span className="sr-only">Close sidebar</span>
                          <XIcon
                            className="h-6 w-6 text-white"
                            aria-hidden="true"
                          />
                        </button>
                      </div>
                    </Transition.Child>
                    <div className="flex-shrink-0 px-4 flex items-center">
                      <h1 className="text-3xl font-bold">Vibe/Rate</h1>
                    </div>
                    <div className="mt-5 flex-1 h-0 overflow-y-auto">
                      <nav className="px-2 space-y-1">
                        {categories.map((item, index) => (
                          <div
                            key={item.name}
                            href={item.href}
                            onClick={() => {
                              selectNavItem(index)
                            }}
                            className={classNames(
                              currentItem == index
                                ? 'bg-gray-100 text-gray-900'
                                : 'text-gray-600 hover:bg-gray-50 hover:text-gray-900',
                              'group rounded-md py-2 px-2 flex items-center text-base font-medium',
                            )}
                          >
                            <item.icon
                              className={classNames(
                                item.current
                                  ? 'text-gray-500'
                                  : 'text-gray-400 group-hover:text-gray-500',
                                'mr-4 flex-shrink-0 h-6 w-6',
                              )}
                              aria-hidden="true"
                            />
                            {item.name}
                          </div>
                        ))}
                        {addNew && (
                          <form>
                            <input id="add-new" type="text" autofocus />
                          </form>
                        )}
                      </nav>
                    </div>
                  </Dialog.Panel>
                </Transition.Child>
                <div className="flex-shrink-0 w-14">
                  {/* Dummy element to force sidebar to shrink to fit close icon */}
                </div>
              </div>
            </Dialog>
          </Transition.Root>

          {/* Static sidebar for desktop */}
          <div className="hidden md:flex md:w-64 md:flex-col md:fixed md:inset-y-0">
            {/* Sidebar component, swap this element with another sidebar if you like */}
            <div className="border-r border-gray-200 pt-5 flex flex-col flex-grow bg-white overflow-y-auto">
              <div className="flex-shrink-0 px-4 flex items-center">
                <h1 className="text-3xl font-bold">Vibe/Rate</h1>
              </div>
              <div className="flex-grow mt-5 flex flex-col">
                <nav className="flex-1 px-2 pb-4 space-y-1">
                  {categories.map((item, index) => (
                    <div
                      key={item.name}
                      href={item.href}
                      onClick={() => {
                        selectNavItem(index)
                      }}
                      className={classNames(
                        currentItem == index
                          ? 'bg-gray-100 text-gray-900'
                          : 'text-gray-600 hover:bg-gray-50 hover:text-gray-900',
                        'group rounded-md py-2 px-2 flex items-center text-sm font-medium',
                      )}
                    >
                      <item.icon
                        className={classNames(
                          item.current
                            ? 'text-gray-500'
                            : 'text-gray-400 group-hover:text-gray-500',
                          'mr-3 flex-shrink-0 h-6 w-6',
                        )}
                        aria-hidden="true"
                      />
                      {item.name}
                    </div>
                  ))}

                  <Modal show={show} onHide={handleClose}>
                    <Modal.Header closeButton>
                      <Modal.Title>
                        {!editCat ? 'Create New List' : 'Update List'}
                      </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                      <label className="mb-2 text-sm font-medium text-gray-900 dark:text-gray-300">
                        Name
                      </label>
                      {currentItem != -1 && (
                        <input
                          id="share-url"
                          style={{ display: 'none' }}
                          value={
                            userInfo
                              ? userInfo.userName
                                ? `https://rabbithole-51919.web.app/u/${
                                    userInfo.userName
                                  }/${categories[currentItem].name
                                    .replace(/\s+/g, '-')
                                    .toLowerCase()}`
                                : `https://rabbithole-51919.web.app/to/${user.uid}/${categories[currentItem].id}`
                              : `https://rabbithole-51919.web.app/to/${user.uid}/${categories[currentItem].id}`
                          }
                        />
                      )}
                      <input
                        type="text"
                        placeholder={
                          !editCat ? '' : `${categories[currentItem].name}`
                        }
                        id="add-new"
                        className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                        autoFocus
                        ref={nameRef}
                        onChange={(e) => {
                          setNewCategory(e.target.value)
                        }}
                      />
                      <label
                        for="name"
                        className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                      >
                        What's your purpose?
                      </label>
                      <Select
                        isMulti
                        placeholder="Tag as many as you'd like!"
                        options={Purposes}
                        onChange={onChange}
                      />
                    </Modal.Body>
                    <Modal.Footer>
                      <Button variant="secondary" onClick={handleClose}>
                        Close
                      </Button>
                      <Button variant="primary" onClick={handleSave}>
                        Save Changes
                      </Button>
                      {editCat && publishButton}
                    </Modal.Footer>
                  </Modal>

                  <div
                    key={'addNew'}
                    href={'#'}
                    onClick={() => {
                      // setCurrentItem(-1)
                      handleShow()
                      document.getElementById('add-new').focus()
                    }}
                    className={classNames(
                      currentItem == -1
                        ? 'bg-gray-100 text-gray-900'
                        : 'text-gray-600 hover:bg-gray-50 hover:text-gray-900',
                      'group rounded-md py-2 px-2 flex items-center text-sm font-medium',
                    )}
                  >
                    <FolderAddIcon
                      className={classNames(
                        currentItem == -1
                          ? 'text-gray-500'
                          : 'text-gray-400 group-hover:text-gray-500',
                        'mr-3 flex-shrink-0 h-6 w-6',
                      )}
                      aria-hidden="true"
                    />
                    Add New
                  </div>
                </nav>
              </div>
            </div>
          </div>
          {/* Main Body - Medium */}
          <div className="md:pl-64">
            <div className="max-w-4xl mx-auto flex flex-col md:px-8 xl:px-0">
              <div className="sticky top-0 z-10 flex-shrink-0 h-16 bg-white border-b border-gray-200 flex">
                <button
                  type="button"
                  className="border-r border-gray-200 px-4 text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500 md:hidden"
                  onClick={() => setSidebarOpen(true)}
                >
                  <span className="sr-only">Open sidebar</span>
                  <MenuAlt2Icon className="h-6 w-6" aria-hidden="true" />
                </button>
                <div className="flex-1 flex justify-between px-4 md:px-0">
                  <div className="flex-1 flex">
                    <form
                      className="w-full flex md:ml-0"
                      action="#"
                      method="GET"
                    >
                      <label htmlFor="search-field" className="sr-only">
                        Search
                      </label>
                      <div className="relative w-full text-gray-400 focus-within:text-gray-600">
                        <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center">
                          <SearchIcon className="h-5 w-5" aria-hidden="true" />
                        </div>
                        <input
                          id="search-field"
                          className="block h-full w-full border-transparent py-2 pl-8 pr-3 text-gray-900 placeholder-gray-500 focus:outline-none focus:placeholder-gray-400 focus:ring-0 focus:border-transparent sm:text-sm"
                          placeholder="Search"
                          type="search"
                          name="search"
                          onClick={(e) => {
                            alert('Search is coming soon!')
                          }}
                        />
                      </div>
                    </form>
                  </div>
                  <div className="ml-4 flex items-center md:ml-6">
                    <button
                      type="button"
                      className="bg-white p-1 rounded-full text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                      onClick={() => {
                        alert('No new notifications!')
                      }}
                    >
                      <span className="sr-only">View notifications</span>
                      <BellIcon className="h-6 w-6" aria-hidden="true" />
                    </button>

                    {/* Profile dropdown */}
                    <Menu as="div" className="ml-3 relative">
                      <div>
                        <Menu.Button className="max-w-xs flex items-center text-sm rounded-full focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                          <span className="sr-only">Open user menu</span>
                          <img
                            className="h-8 w-8 rounded-full"
                            src={ProfileImage}
                            alt=""
                          />
                        </Menu.Button>
                      </div>
                      <Transition
                        as={Fragment}
                        enter="transition ease-out duration-100"
                        enterFrom="transform opacity-0 scale-95"
                        enterTo="transform opacity-100 scale-100"
                        leave="transition ease-in duration-75"
                        leaveFrom="transform opacity-100 scale-100"
                        leaveTo="transform opacity-0 scale-95"
                      >
                        <Menu.Items className="origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 py-1 focus:outline-none">
                          {userNavigation.map((item, index) => (
                            <Menu.Item key={item.name}>
                              {({ active }) => (
                                <div
                                  href={item.href}
                                  onClick={item.action}
                                  className={classNames(
                                    active ? 'bg-gray-100' : '',
                                    'block py-2 px-4 text-sm text-gray-700',
                                  )}
                                >
                                  {item.name}
                                </div>
                              )}
                            </Menu.Item>
                          ))}
                        </Menu.Items>
                      </Transition>
                    </Menu>
                  </div>
                </div>
              </div>

              <main className="flex-1">
                <div className="py-6">
                  <div className="px-4 sm:px-6 md:px-0">
                    <h1 className="text-2xl font-semibold text-gray-900">
                      {navTitle}
                    </h1>
                  </div>
                  <div className="px-4 sm:px-6 md:px-0" key={currentItem}>
                    {/* Replace with your content */}
                    {currentItem == 0 ? (
                      <Dashboard handleShow={handleShow} />
                    ) : currentItem == -1 ? (
                      <Profile />
                    ) : (
                      <LinkContainer
                        index={currentItem}
                        data={categories[currentItem]}
                        copyShareLink={copyShareLink}
                        handleShow={handleShow}
                        setPublishStatus={setPublishStatus}
                      />
                    )}
                    {/* /End replace */}
                  </div>
                </div>
              </main>
            </div>
          </div>
        </div>
      )}
    </>
  )
}
